<template>
  <v-container class="creator" v-if="image && image.url">
    <v-row>
      <v-col cols="12">
        <h2>Creator</h2>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="input.title"
                  label="Title"
                  required
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="input.text"
                  label="Beschreibung"
                  required
                ></v-textarea
              ></v-col>
              <v-col cols="12" md="6"
                ><v-text-field
                  v-model="input.price"
                  label="Preis"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6"
                ><v-text-field
                  v-model="input.weight"
                  label="Gewicht"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6"
                ><v-btn
                  color="primary"
                  class="mr-4"
                  depressed
                  :loading="isDownloading"
                  :disabled="isDownloading"
                  @click="downloadImage()"
                >
                  <v-icon class="mr-4">mdi-download</v-icon>
                  Herunterladen
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group v-model="theme" row>
                  <v-radio label="Hochformat" value="portrait"></v-radio>
                  <v-radio label="Querformat" value="landscape"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-col cols="12">
        <h2>Preview:</h2>
      </v-col>
      <v-col
        cols="12"
        class="teplateWrapper mb-8"
        :style="{ maxHeight: getSize() + 'px' }"
      >
        <ThemePortrait
          v-if="theme === 'portrait'"
          id="imageTemplate"
          :class="{ scaleHeight: !isDownloading }"
          :img="resizedName(image.url)"
          :input="input"
        />
        <ThemeLandscape
          v-if="theme === 'landscape'"
          id="imageTemplate"
          :class="{ scaleWide: !isDownloading }"
          :img="resizedName(image.url)"
          :input="input"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="isDownloading"
      overlay-opacity="0.9"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Bild wird heruntergeladen
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import firebase from "firebase/app";
import ThemePortrait from "@/components/ThemePortrait";
import ThemeLandscape from "@/components/ThemeLandscape";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

export default {
  name: "Creator",
  components: {
    ThemePortrait,
    ThemeLandscape
  },
  data: () => ({
    image: "",
    isEditing: true,
    isDownloading: false,
    input: {
      title: "Schlesier",
      text:
        "Roggenmischbrot mit hauseigenem milden Natursauer und Jodsalz nur für dich gebacken. ",
      price: "3,45€",
      weight: "750g"
    },
    theme: "portrait"
  }),
  methods: {
    downloadUrl(ref) {
      return ref.getDownloadURL().then(url => {
        console.log(url);
        this.image = url;
      });
    },
    getSize() {
      if (this.theme === "landscape") {
        return 600;
      }
      return 1200;
    },
    resizedName(fileName) {
      const original = fileName.replace("_800x800", "");
      return `${original}`;
    },
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    downloadImage() {
      this.isDownloading = true;
      let options = {
        height: 2832,
        width: 4240,
        quality: 1
      };
      if (this.theme === "portrait") {
        options = {
          height: 4240,
          width: 2832,
          quality: 1
        };
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      const imageName = this.StringToSlug(this.input.title);
      domtoimage
        .toBlob(document.getElementById("imageTemplate"), options)
        .then(function(blob) {
          window.saveAs(blob, imageName);
          vm.isDownloading = false;
          vm.writeImageData(blob, imageName);
        });
    },
    StringToSlug(str) {
      str = str.replace(/^\s+|\s+$/g, "");
      str = str.toLowerCase();
      const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      const to = "aaaaeeeeiiiioooouuuunc------";
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-");
      return `${str}_${new Date().getTime()}.png`;
    }
  },
  mounted: function() {
    const index = Number.parseInt(this.$route.params.index);
    console.log(index);
    if (Number.isNaN(index)) {
      return 0;
    }

    const currentItem = this.$store.state.images[index];
    this.image = currentItem;

    const theImage = new Image();
    theImage.src = currentItem.url;

    const imageWidth = theImage.width;
    const imageHeight = theImage.height;

    console.log(`Image height: ${imageHeight} Image width: ${imageWidth}`);
    if (imageHeight > imageWidth) {
      this.theme = "portrait";
    } else {
      this.theme = "landscape";
    }
    //this.downloadUrl(currentItem.item);
    // console.log(this.$store.state.images[item]);
    // currentItem.item.getDownloadURL().then(url => {
    //   console.log(url);
    //   this.image = url;
    // });
  },
  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav);
  // },

  // beforeDestroy() {
  //   window.removeEventListener("beforeunload", this.preventNav);
  // },

  computed: {
    // getUrl() {
    //   return this.$route.params.url;
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
</style>
