<template>
  <div
    class="canvas canvasHeight"
    :style="{ backgroundImage: 'url(' + img + ')' }"
  >
    <div class="content contentHeight">
      <h2>{{ input.title }}</h2>
      <p>{{ input.text }}</p>
      <span>{{ input.price }} </span
      ><span class="weight" v-if="input.weight">/ {{ input.weight }}</span>
    </div>
    <hr />
    <img class="logo" src="@/assets/logo.png" />
  </div>
</template>
<script>
export default {
  props: ["img", "input"]
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.canvas {
  margin-top: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.canvasHeight {
  width: 2832px;
  height: 4240px;

  font-size: 5em;
}
.scaleHeight {
  $scaleHeight: 0.24011299;
  transform: scale($scaleHeight);
  -webkit-transform: scale($scaleHeight);
  -moz-transform: scale($scaleHeight);
  -o-transform: scale($scaleHeight);
  transform: scale($scaleHeight);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
}
hr {
  background: $secondary;
  border: 0;
  height: 12px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 5.8em;
}
.logo {
  position: absolute;
  top: 3em;
  right: 1.7em;
  width: 7em;
  height: 7em;
}
.content {
  position: absolute;
  padding: 4.5em 2em;
  color: $secondary;
  h2 {
    font-size: 3.2em;
    margin: 0;
    text-transform: uppercase;
    font-family: "EB Garamond", serif;
  }
  p {
    font-size: 1em;
    margin: 0.3em 0;
    max-width: 90%;
  }
  span {
    font-size: 2em;
    font-family: "EB Garamond", serif;
  }
  .weight {
    font-size: 1em;

    margin-top: 1.2em;
  }
}

.contentHeight {
  bottom: 1.4em;
}
</style>
