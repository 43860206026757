<template>
  <div
    class="canvas canvasWide"
    :style="{ backgroundImage: 'url(' + img + ')' }"
  >
    <div class="content contentWide">
      <h2>{{ input.title }}</h2>
      <p>{{ input.text }}</p>
      <span>{{ input.price }} </span
      ><span class="weight" v-if="input.weight">/ {{ input.weight }}</span>
    </div>
    <img class="logo" src="@/assets/logo.png" />
  </div>
</template>
<script>
export default {
  props: ["img", "input"]
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.canvas {
  margin-top: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.canvasWide {
  width: 4240px;
  height: 2832px;
  font-size: 5em;
}
.scaleWide {
  $scaleWide: 0.2;

  transform: scale($scaleWide);
  -webkit-transform: scale($scaleWide);
  -moz-transform: scale($scaleWide);
  -o-transform: scale($scaleWide);
  transform: scale($scaleWide);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  outline: 1px solid transparent;
  hr {
    background: $secondary;
    height: 100%;
    width: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5.3em;
  }
}

.logo {
  position: absolute;
  top: 3.5em;
  right: 1.7em;
  width: 7em;
  height: 7em;
}
.content {
  position: absolute;
  padding: 4em 2em;
  color: $secondary;
  h2 {
    font-size: 3.2em;
    margin: 0;
    text-transform: uppercase;
    font-family: "EB Garamond", serif;
  }
  p {
    font-size: 1em;
    margin: 0.3em 0;
    max-width: 75%;
  }
  span {
    font-size: 2em;
    font-family: "EB Garamond", serif;
  }
  .weight {
    font-size: 1em;
    margin-top: 1.2em;
  }
}
</style>
